/// <reference path="../../../../Content/angular/angular.d.ts" />
/// <reference path="../models.d.ts" />
/// <reference path="../../../numeral/index.d.ts" />

angular.module("Zotec.PatientExperience",
    [
        "720kb.datepicker",
        "ui.router",
        "ngAnimate",
        "mgcrea.ngStrap",
        "restangular",
        "ngStorage",
        "currencyMask",
        "ui.select",
        "ngSanitize",
        "ngMaterial",
        "ui.mask",
        "vcRecaptcha",
        "Zotec.PatientExperience.Models",
        "Zotec.PatientExperience.Default",
        "Zotec.PatientExperience.EbcPassThrough",
        "Zotec.PatientExperience.Entity",
        "Zotec.PatientExperience.ExternalPayment",
        "Zotec.PatientExperience.Login",
        "Zotec.PatientExperience.Welcome",
        "Zotec.PatientExperience.BillPay",
        "Zotec.PatientExperience.PaymentPlan",
        "Zotec.PatientExperience.AccountHistory",
        "Zotec.PatientExperience.PaperlessBilling",
        "Zotec.PatientExperience.Insurance",
        "Zotec.PatientExperience.InsuranceEdit",
        "Zotec.PatientExperience.InsuranceAdd",
        "Zotec.PatientExperience.InsuranceCharges",
        "Zotec.PatientExperience.InsuranceEncounters",
        "Zotec.PatientExperience.Feedback",
        "Zotec.PatientExperience.Contact",
        "Zotec.PatientExperience.GuarantorPatients",
        "Zotec.PatientExperience.Document",
        "Zotec.PatientExperience.PrePay",
        "Zotec.PatientExperience.GoodFaithEstimate",
        "Zotec.PatientExperience.Support",
        "Zotec.PatientExperience.Survey",
        "Zotec.PatientExperience.Controls",
        "Zotec.PatientExperience.Video",
        "Zotec.PatientExperience.WordDocument",
        "Zotec.PatientExperience.TeleMedAccountHistory",
        "Zotec.PatientExperience.TeleMedBillPay",
        "Zotec.PatientExperience.TeleMedPaymentPlan",
        "Zotec.PatientExperience.ApplePay",
        "Zotec.PatientExperience.Thanks"
    ])
    .config([
        "$httpProvider", "$locationProvider", "$urlRouterProvider", "$urlMatcherFactoryProvider", "$stateProvider", "$datepickerProvider", "$selectProvider", "$popoverProvider",
        "$sceDelegateProvider", "$modalProvider", "RestangularProvider", "$localStorageProvider", "$sessionStorageProvider", "$mdThemingProvider", "uiMask.ConfigProvider",
        ($httpProvider, $locationProvider, $urlRouterProvider, $urlMatcherFactoryProvider, $stateProvider, $datepickerProvider, $selectProvider, $popoverProvider,
            $sceDelegateProvider, $modalProvider, RestangularProvider, $localStorageProvider, $sessionStorageProvider, $mdThemingProvider, uiMaskConfigProvider) => {

            if (!$httpProvider.defaults.headers.get) {
                $httpProvider.defaults.headers.get = {};
            }

            $httpProvider.useApplyAsync(true);
            $httpProvider.interceptors.push([
                "$location", "$q", "$injector", ($location, $q, $injector) => {
                    return {
                        responseError: (response: any) => {
                            if (response.status === 401) {
                                $injector.get("$state").transitionTo("entity.login");
                            }
                            return $q.reject(response);
                        }
                    } as angular.IHttpInterceptor;
                }
            ]);

            $locationProvider.html5Mode(true);

            $localStorageProvider.setKeyPrefix("Zotec.PatientExperience.");
            $sessionStorageProvider.setKeyPrefix("Zotec.PatientExperience.");

            $urlRouterProvider.otherwise("/global/login");

            $sceDelegateProvider.trustedResourceUrlList([
                "self",
                "https://d1barm3815r7ja.cloudfront.net/**",
                "https://dcsokk5etzbbl.cloudfront.net/**",
                "https://dt34nwoeuglj5.cloudfront.net/**",
                "https://d3fb9nylia1ygj.cloudfront.net/**",
                "https://d1am8kq78x1j6s.cloudfront.net/**"
            ]);

            RestangularProvider.setBaseUrl("api");

            numeral.register('locale', 'en-us', {
                delimiters: {
                    thousands: ',',
                    decimal: '.'
                },
                abbreviations: {
                    thousand: 'K',
                    million: 'M',
                    billion: 'B',
                    trillion: 'T'
                },
                ordinal: function (number) {
                    var b = number % 10;
                    return (~~(number % 100 / 10) === 1) ? 'th' :
                        (b === 1) ? 'st' :
                            (b === 2) ? 'nd' :
                                (b === 3) ? 'rd' : 'th';
                },
                currency: {
                    symbol: '$'
                }
            });
            numeral.locale('en-us');

            angular.extend($selectProvider.defaults, {
                placeholder: "Choose One..."
            });

            angular.extend($popoverProvider.defaults, {
                trigger: "click",
                placement: "auto top",
                autoClose: true
            });

            angular.extend($datepickerProvider.defaults, {
                autoclose: true,
                dateFormat: "M/d/yyyy",
                minDate: new Date(1901, 0, 1),
                useNative: true,
                timezone: "UTC"
            });

            angular.extend($modalProvider.defaults, {
                keyboard: false,
                backdrop: "static",
                show: true,
                animation: "am-fade-custom",
                backdropAnimation: "am-fade-custom"
            });

            $mdThemingProvider.disableTheming();

            uiMaskConfigProvider.eventsToHandle(["keypress", "keyup", "click", "focus"]);

        }
    ])
    .run(["$rootScope", "$state", "$document", "$transitions", ($rootScope, $state, $document, $transitions) => {

        $transitions.onStart({},
            (trans) => {
                const from = trans.from().name;
                const to = trans.to().name;
                trans.promise.then(() => {
                    // $stateChangeSuccess
                    $rootScope.previousState = from;
                    $rootScope.currentState = to;
                }).catch(err => {
                    // $stateChangeError
                    if (from !== "entity.login") {
                        $state.go("default");
                    }
                });
            });

        var modals = [];
        // auto track $modal windows
        $rootScope.$on('modal.show', function (e, $modal) {
            // if modal is not already in list
            if (modals.indexOf($modal) === -1) {
                modals.push($modal);
            }
            if (modals.length >= 0) {
                $document.find('body').addClass('modal-open');
            }
        });

        // un-track $modal windows on hide
        $rootScope.$on('modal.hide', function (e, $modal) {
            var modalIndex = modals.indexOf($modal);
            modals.splice(modalIndex, 1);
            if (modals.length === 0) {
                $document.find('body').removeClass('modal-open');
            }
        });
    }]);