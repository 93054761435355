/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.ExternalPayment")
    .directive("zpExternalPayment", () => {
        return {
            restrict: "AE",
            controller: "zpExternalPaymentController",
            controllerAs: "zpExternalPayment",
            bindToController: true,
            replace: true,
            templateUrl: "Templates/PatientExperience/ExternalPayment/zpExternalPayment"
        }
    });