/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.Default")
    .controller("zpDefaultController", ["Restangular", "$state", "$siteSettings", "$guarantor", "$timeout", "$window", "$sessionStorage", "$location",
        (Restangular: restangular.IService, $state, $siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.GuarantorService, $timeout, $window, $sessionStorage, $location) => {
            
            const port = $window.location.port !== "" && $window.location.port !== 80 && $window.location.port !== 433
                ? ":" + $window.location.port
                : "";
            if ($state.params.u != null) {
                $sessionStorage.$reset();
                Restangular.one("directlink", $state.params.u).get().then((details: zotec.patientExperience.DirectLink) => {
                    if (details != null) {

                        const urlParts = details.redirectUrl.split('/'); 
                        if (urlParts[0].toLowerCase() !== $location.host().toLowerCase()) {
                            $window.location.href = $window.location.protocol + "//" + urlParts[0] + port + "/?u=" + $state.params.u;
                            return;
                        }

                        const redirectUrl = urlParts.length > 1
                            ? urlParts[0] + port + "/" + urlParts[1]
                            : urlParts[0] + port;

                        $guarantor.guarantorId = details.guarantorId;
                        $guarantor.chargeId = details.chargeId;
                        $guarantor.destination = details.destination;

                        $sessionStorage.guarantorId = details.guarantorId;
                        $sessionStorage.chargeId = details.chargeId;
                        $sessionStorage.destination = details.destination;
                        $sessionStorage.paymentsOnPatientDeviceDataID = details.paymentsOnPatientDeviceDataID;

                        $sessionStorage.directLinkId = $state.params.u;
                        $sessionStorage.fromDirectLink = !!($sessionStorage.directLinkId);
                        $sessionStorage.directLinkBillingGroupId = details.billingGroupId;

                        $sessionStorage.clientId = details.clientId;
                        $sessionStorage.dataSourceId = details.dataSourceId;
                        $sessionStorage.autoAuthenticate = details.autoAuthenticate;
                        $sessionStorage.externalRedirectUrl = details.externalRedirectUrl;
                        $sessionStorage.$apply();
                        $window.location.href = $window.location.protocol + "//" + redirectUrl;
                        
                        return;
                    }
                    $location.search({});
                }, () => {
                    $location.search({});
                });
            } else {
                Restangular.one("siteurldetails").get({ siteUrl: $location.host(), entityId: "" }).then(
                    (details: zotec.patientExperience.SiteUrlDetails) => {
                        if (details.clientId && details.clientId !== "") {
                            $window.location.href = $window.location.protocol + "//" + details.siteUrl + port + "/" + details.clientId;
                        } else {
                            $window.location.href = "global";
                        }
                        return;
                    });
            }
            
        }
    ]);
