/// <reference path="../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Controls").factory("$emailReceiptModal", ["$modal", "$rootScope", "$siteSettings", "$zpModal",
    ($modal, $rootScope, $siteSettings, $zpModal) => {

        function $emailReceiptModal(paymentId: number, isTOSReceipt: boolean = false, tokenizedAmount: number = 0) {
            var scope = $rootScope.$new();
            scope.emailAddress = null as string;
            var modal = $modal({
                templateUrl: "Templates/Controls/Services/EmailReceiptModal",
                scope: scope
            });

            scope.emailReceipt = (emailAddress: string) => {
                scope.setSubmitButtonState(true);
                $siteSettings.billingGroupRestangularized().all("receipt").all("email").post({ emailAddress: emailAddress, paymentId: paymentId, isTOSReceipt: isTOSReceipt, tokenizedAmount: tokenizedAmount })
                    .then(() => {
                        scope.setSubmitButtonState(false);
                        $zpModal("Receipt sent", "We sent your receipt to " + emailAddress, () => { });
                        scope.hideEmailReceiptModal();
                    }, () => {
                        scope.setSubmitButtonState(false);
                        $zpModal("Error sending receipt", "There was an error while emailing your receipt. If you do not receive your email shortly, please try again.", () => { });
                    });
            };

            scope.setSubmitButtonState = (submitting: boolean) => {
                var submitButton = document.getElementById('email-receipt-submit-button');
                if (submitting) {
                    submitButton.classList.add('submitting');
                    submitButton.classList.add('disabled');
                } else {
                    submitButton.classList.remove('submitting');
                    submitButton.classList.remove('disabled');
                }
            }

            scope.hideEmailReceiptModal = () => {
                modal.hide();
            }

            return modal;
        }

        return $emailReceiptModal;
    }
]);
