/// <reference path="../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../Scripts/typings/jquery/jquery.d.ts" />

angular.module("Zotec.PatientExperience.Controls")
    .directive("zpNavBar", ["$siteSettings", "$guarantor", "$state", "$modal", "$loggingService", "$q", "$paperlessBillingState", "$paperlessBillingSelector", "$sessionStorage", "$siteTimeoutService",
        ($siteSettings: zotec.patientExperience.Services.SiteSettingsService, $guarantor: zotec.patientExperience.Services.
            GuarantorService, $state, $modal, $loggingService, $q: angular.IQService,
            $paperlessBillingState: zotec.patientExperience.Services.PaperlessBillingStateService,
            $paperlessBillingSelector, $sessionStorage, $siteTimeoutService: zotec.patientExperience.Services.SiteTimeoutService
        ) => {
        return {
            templateUrl: "Templates/Controls/Directives/NavBar",
            restrict: "E",
            link: (scope: NavBarScope, element: JQuery, attributes: angular.IAttributes) => {

                scope.showGreeting = () => {
                    if ($guarantor && $guarantor.firstName) {
                        scope.greeting = 'HI, ' + $guarantor.firstName;
                        return true;
                    }
                    return false;
                }

                scope.isLoggedIn = () => {
                    return $guarantor.isLoggedIn && scope.showElement();
                }

                scope.isSurveyExp = () => {
                    return $state.is("entity.surveyexp");
                }

                scope.showElement = () => {
                    return !scope.isSurveyExp() && !scope.isTelepayPatientMenu();
                }
                scope.isTelepayPatientMenu = () => {
                    return $state.is("entity.tele-med-account-history") ||
                        $state.is("entity.tele-med-payment-plan") ||
                        $state.is("entity.tele-med-bill-pay") ||
                        $sessionStorage.destination === 'TOSPayment';
                }

                scope.showContactUs = () => {
                    return $siteSettings.clientId != null && scope.showElement();
                }

                scope.showInsuranceButton = () => {
                    return $guarantor.isLoggedIn && !$siteSettings.isGuarantorBillingClient;
                }
                
                scope.showLogOutButton = () => {
                    return $guarantor.isLoggedIn;
                }
                scope.showLogInButton = () => {
                    return scope.showElement() && !$guarantor.isLoggedIn ? !$state.is("entity.login") : false;
                }

                scope.showMenu = () => {
                    return !$state.is("default") && !$state.is("entity") && !$state.is("entity.external-payment");
                }

                scope.logOut = () => {
                    $guarantor.logOut();
                    $siteTimeoutService.CancelCurrentTimeout();
                }

                scope.allowPrePay = () => {
                    return $siteSettings.allowPrePay && !$guarantor.isLoggedIn && scope.showElement();
                }

                scope.allowGoodFaithEstimate = () => {
                    return $siteSettings.allowGoodFaithEstimate && !$guarantor.isLoggedIn && scope.showElement();
                }

                scope.feedbackUrl = () => {
                    return $siteSettings.feedbackUrl;
                }

                scope.useFeedbackOverride = () => {
                    return $siteSettings.feedbackUrl && $siteSettings.feedbackUrl.length > 0;
                }

                scope.openNotificationSettingsModal = () => {
                    let statePromise = $guarantor.getEnrollmentState();
                    let emailSettingsPromise = $paperlessBillingState.getEmailSettings();
                    $q.all([statePromise, emailSettingsPromise]).then(results => {
                        $paperlessBillingState.enrollmentState = results[0];
                        $paperlessBillingSelector(results[1]);
                    });
                }
            }
        };
    }]);

interface NavBarScope extends angular.IScope {
    showGreeting: Function;
    greeting: string;
    isLoggedIn: Function;
    showLogInButton: Function;
    showLogOutButton: Function;
    showInsuranceButton: Function;
    showMenu: Function;
    logOut: Function;
    allowPrePay: Function;
    allowGoodFaithEstimate: Function;
    showContactUs: Function;
    showClientLogin: Function;
    clientLogin: Function;
    showNotificationSettings: Function;
    feedbackUrl: Function;
    useFeedbackOverride: Function;
    openNotificationSettingsModal: Function;
}