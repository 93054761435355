/// <reference path="../../../../../../Content/angular/angular.d.ts" />
/// <reference path="../../../../../../Scripts/typings/moment/moment.d.ts" />
/// <reference path="../../../../../lodash/lodash.d.ts" />
/// <reference path="../../../models.d.ts" />

angular.module("Zotec.PatientExperience.ExternalPayment")
    .controller("zpExternalPaymentController", ["$scope", "Restangular", "$state", "$siteSettings", "$guarantor", "$pdf", "$q",
        "$paymentFailureModal", "$emailReceiptModal", "$loadPaymentModuleLibrary", "$mdsService", "$window", "PaymentModuleFormService", "$sessionStorage",
        function ($scope, Restangular: restangular.IService, $state, $siteSettings: zotec.patientExperience.Services.SiteSettingsService,
            $guarantor: zotec.patientExperience.Services.GuarantorService, $pdf: zotec.patientExperience.Services.PdfService,
            $q: angular.IQService, $paymentFailureModal, $emailReceiptModal, $loadPaymentModuleLibrary,
            $mdsService: zotec.patientExperience.Services.MDSService,
            $window: angular.IWindowService,
            $paymentModuleFormService: zotec.patientExperience.Services.IPaymentModuleFormService,
            $sessionStorage) {

            const self = this;

            self.internalRequest = Restangular.one("internalnetwork").get().$object;

            self.LOADING_STATE = 0;
            self.NORMAL_STATE = 1;
            self.ERROR_STATE = 2;

            self.currentState = self.LOADING_STATE;

            self.PAYMENT_PAGE = 0;
            self.SUCCESS_PAGE = 1;
            self.LOADING_PAGE = 2;

            self.currentPage = self.PAYMENT_PAGE;
            self.externalRedirectUrl = $sessionStorage.externalRedirectUrl;

            self.guarantorBalance = (self.guarantorBalance - self.paymentAmount).toFixed(2);
            self.successfulPayment = (null as PaymentSummary);

            self.checkCreditCardTermsAgreement = false;
            self.checkTokenizationAgreement = false;

            self.$onInit = () => {
                $scope.$watch(() => self.paymentAmount, (newVal, oldVal) => {
                    if (newVal != undefined && newVal != oldVal && self.paymentModuleFormOptions !== undefined) {
                        self.paymentModuleFormOptions.paymentAmount = newVal;
                    }
                });
            }

            self.creditCardFormHandle = null;
            self.echeckFormHandle = null;
            self.isApplePayActive = false;
            self.enableCreditCardFormWithBillingAddress = false;

            self.isLoading = () => {
                return self.currentState == self.LOADING_STATE;
            }

            self.isInErrorState = () => {
                return self.currentState == self.ERROR_STATE;
            }

            self.isInNormalState = () => {
                return self.currentState == self.NORMAL_STATE;
            }

            self.telemedBalance = {};
            self.guarantorBalance = 0;
            self.paymentAmount = 0;
            self.sessionData = null;
            self.originatingUserInfo = {};

            self.goToErrorState = (logErrorMessage: string) => {
                if (!self.isInErrorState()) {
                    self.currentState = self.ERROR_STATE;
                    $paymentFailureModal();
                }
                self.logError(logErrorMessage);
            }

            self.logError = (message: string) => {
                $siteSettings.billingGroupRestangularized().all("PostProcess").all("Log")
                    .post({ logMessage: message, guarantorId: $guarantor.guarantorId, transactionId: self.transactionId, consoleErrors: self.loadingLibraryErrors });
            }

            self.applePayInitErrorHandler = (message: string) => {
                self.logError(message);
            }

            self.splunkErrorLog = console.error.bind(console);
            self.loadingLibraryErrors = [];
            console.error = function () {
                var argumentsList = [];
                for (var i = 0; i < arguments.length; i++) {
                    argumentsList.push(arguments[i]);
                }
                self.loadingLibraryErrors.push(argumentsList);
                self.splunkErrorLog.apply(console, arguments);
            }.bind(this);

            self.getTermsOfUseUrl = () => {
                var returnUrl = $siteSettings.getTermsOfUseUrl();
                return returnUrl;
            }

            let paymentModuleLibraryPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("Library").get()
                .then((response: string) => {
                    $loadPaymentModuleLibrary(response);
                }, () => {
                    self.goToErrorState("External pay was unable to load the Payment Module.");
                });

            let sessionDataPromise = $siteSettings.billingGroupRestangularized().all("PaymentModule").one("SessionData").get()
                .then((data: string) => {
                    self.sessionData = data;
                }, () => {
                    self.goToErrorState("Bill pay was unable to load the Payment Module.");
                });

            let balancePromise = $guarantor.restangularized()
                .all("TelemedPayment").one("TelemedBalance").get({ omitDetails: true })
                .then((telemedBalance: any) => {
                    self.telemedBalance = telemedBalance;
                    self.guarantorBalance = telemedBalance.todaysBalance || 0.0;
                    self.paymentAmount = telemedBalance.todaysBalance || 0.0;
                }, () => {
                    self.goToErrorState("External pay was unable to retrieve client information for user.");
                });

            self.availablePaymentMethods = [];
            self.payOnlyPhone = null as string;
            self.canPayOnline = null as boolean;

            const originatingUserInfoPromise = $siteSettings.billingGroupRestangularized()
                .all("PaymentModule")
                .get("OriginatingUserInfo")
                .then((oui) => self.originatingUserInfo = oui)
                .catch(() => {
                    $siteSettings.billingGroupRestangularized().all("PaymentModule").all("Log").post({
                        clientId: $siteSettings.clientId,
                        billingGroupId: $siteSettings.billingGroupId,
                        logMessage: "Could not load the Payment Module library and getting originating user info."
                    });
                    $paymentFailureModal();
                });

            let billingGroupClientInfoPromise = $siteSettings.billingGroupRestangularized().one("clientinfo").get()
                .then((info: zotec.patientExperience.client.ClientInfo) => {
                    self.tokenizedAmount = info.tokenizedAmount
                }, () => {
                    self.goToErrorState("External pay was unable to retrieve client information for user.");
                });

            $q.all([originatingUserInfoPromise,
                sessionDataPromise,
                balancePromise,
                billingGroupClientInfoPromise,
                paymentModuleLibraryPromise
            ]).finally(() => {
                self.postInitHandler();
            });

            self.transactionId = null as string;
            self.acceptedCreditCards = [];

            self.getPaymentId = (responses: zotec.patientExperience.payments.TOSPaymentResponse[]) => {
                if (responses && responses.length === 0) {
                    return 0;
                }

                var response = responses[0];
                if (response) {
                    return response.success ? response.paymentId : 0;
                } else {
                    return 0;
                }
            }

            self.getConfirmationNumber = (responses: zotec.patientExperience.payments.TOSPaymentResponse[]) => {
                if (responses && responses.length === 0) {
                    return 'Unknown';
                }

                return responses[0].approvalCode;
            }

            self.handlePaymentCompletion = (response: zotec.patientExperience.payments.WebHookResponse) => {
                if (response.isSuccess) {
                    response.parsedWebHookResponse = response.webhookResponse ? JSON.parse(response.webhookResponse) : undefined;
                    const paymentId = response.parsedWebHookResponse ? self.getPaymentId(response.parsedWebHookResponse.responses) : 0;
                    const confirmationNumber = response.parsedWebHookResponse ? self.getConfirmationNumber(response.parsedWebHookResponse.responses) : 'Unknown';
                    self.guarantorBalance = (self.guarantorBalance - self.paymentAmount).toFixed(2);
                    self.successfulPayment = new PaymentSummary("Payment Successful", self.paymentAmount
                        , self.guarantorBalance, confirmationNumber, paymentId
                        , 'Credit Card' + ' ' + response.maskedAccountNumber.substr(8));
                    self.paymentAmount = self.guarantorBalance;
                    self.currentPage = self.SUCCESS_PAGE;
                    $mdsService.initiateReceiptAfterSuccessfulPayment(paymentId).then(
                        (receiptResult: zotec.patientExperience.MDS.InitiateReceiptResponse) => {
                            self.receiptInitiliazed = receiptResult.success;
                        },
                        (error) => {
                            self.receiptInitiliazed = false;
                            self.logError(error);
                        });
                } else {
                    response.parsedWebHookResponse = response.webhookResponse ? JSON.parse(response.webhookResponse) : undefined;
                    const errorMessage = response.parsedWebHookResponse ? response.parsedWebHookResponse.responses[0].errorMessage : "Something went wrong trying to make the payment.";
                    self.goToErrorState(errorMessage);
                }
                $scope.$digest();
            }

            self.printReceipt = () => {
                $pdf.open($siteSettings.billingGroupRestangularized().getRestangularUrl() + "/receipt/download", { paymentId: self.successfulPayment.paymentId, isPrePay: false, isTOSReceipt: true, tokenizedAmount: self.tokenizedAmount  }, "Generating receipt...", null);
            }

            self.showEmailReceiptModal = () => {
                $emailReceiptModal(self.successfulPayment.paymentId, true, self.tokenizedAmount);
            }

            self.logOut = () => {
                self.currentPage = self.LOADING_PAGE;
                Restangular.all("authentication")
                    .remove().then((result: boolean) => {
                        $sessionStorage.$reset();
                        $window.location.href = self.externalRedirectUrl;
                    }, (response) => {
                        self.logError(response);
                        $state.go("entity.login");
                    });
            }

            self.postInitHandler = () => {
                if (!self.isInErrorState()) {
                    self.currentState = self.NORMAL_STATE;

                    self.paymentModuleApi = $window["PaymentModule"];

                    self.paymentModuleFormOptions = {
                        chargeType: "TOS",
                        appName: "TOS",
                        entityId: self.telemedBalance.entityId,
                        locationId: self.telemedBalance.locationId,
                        sessionData: self.sessionData,
                        charges: [],
                        paymentAmount: self.paymentAmount,
                        originatingUserInfo: self.originatingUserInfo,
                        onSuccessCallback: self.handlePaymentCompletion,
                        webhookUrl: self.buildWebhookUrl(),
                        tokenizedAmount: self.tokenizedAmount
                    } as zotec.patientExperience.PaymentModuleFormOptions;

                    const ccForm = self.paymentModuleApi.InitCreditCardForm("payment-module-credit-card-container", { includeSwipe: false, includeNotes: false, includeBillingAddress: true });
                    self.creditCardFormHandle = $paymentModuleFormService.configureForm(ccForm, self.paymentModuleFormOptions);
                    self.creditCardFormHandle.Disable();
                }
            }

            self.updateCheckBox = () => {
                if (self.checkCreditCardTermsAgreement && self.checkTokenizationAgreement) {
                    self.creditCardFormHandle.Enable();
                } else {
                    self.creditCardFormHandle.Disable();
                }
            }

            self.buildWebhookUrl = (): string => {
                return `${$window.location.origin}/api/datasources/${$siteSettings.dataSourceId}/clients/${$siteSettings.clientId}/billinggroups/${$siteSettings.billingGroupId}/PostProcess/TelemedPayment/${$guarantor.guarantorId}`
            }
        }]);
