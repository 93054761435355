/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.Insurance", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.insurance", {
            url: "/insurance",
            template: "<zp-insurance></zp-insurance>",
            params: {
                pending: false,
                noRefile: false,
                encounterDetails: (null as zotec.patientExperience.insurance.EncounterDetails)
            },
            resolve: {
                resolver: ["AuthenticationModel", "entity", "$q", "$state", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails, $q, $state) => {
                    return AuthenticationModel.authenticate("Insurance", entity)
                        .then(function (externalPaymentOnly) {
                            if (externalPaymentOnly) {
                                $state.go("entity.external-payment");
                                return $q.reject();
                            }
                            return $q.resolve();
                        });
                }]
            }
        });
    }]);