/// <reference path="../../../../../../Content/angular/angular.d.ts" />

angular.module("Zotec.PatientExperience.ExternalPayment", [])
    .config(["$stateProvider", ($stateProvider) => {
        $stateProvider.state("entity.external-payment", {
            url: "/external-payment",
            template: "<zp-external-payment></zp-external-payment>",
            resolve: {
                resolver: ["AuthenticationModel", "entity", (AuthenticationModel: zotec.patientExperience.resolvers.AuthenticationModel, entity: zotec.patientExperience.SiteUrlDetails) => {
                    return AuthenticationModel.authenticate("External Payment", entity);
                }]
            }
        });
    }]);