angular
    .module('Zotec.PatientExperience.Controls')
    .factory('PaymentModuleFormService', paymentModuleFormService);

paymentModuleFormService.$inject = ["$siteSettings", "$guarantor"];

function paymentModuleFormService($siteSettings, $guarantor) {
    var service = {
        configureForm: configureForm
    };

    return service;

    function configureForm(pmForm: zotec.patientExperience.payments.IPaymentModuleForm, options: zotec.patientExperience.PaymentModuleFormOptions,
        onInitCallback: IFormCallback = () => { }): zotec.patientExperience.payments.IPaymentModuleForm {
        const optionsCopy = _.clone(options);
        pmForm = pmForm.OnSuccess(optionsCopy.onSuccessCallback)
            .WithCharges(optionsCopy.charges)
            .WithChargeType(optionsCopy.chargeType)
            .WithAppName(optionsCopy.appName)
            .WithWebHook(optionsCopy.webhookUrl, optionsCopy.sessionData)
            .ConfigurePatientInternal({
                dataSourceId: $siteSettings.dataSourceId,
                clientId: $siteSettings.clientId,
                billingGroupId: $siteSettings.billingGroupId,
                entityId: optionsCopy.entityId,
                guarantorId: $guarantor.guarantorId,
                locationId: optionsCopy.locationId,
                patientId: 0
            })
            .OnVerificationRequest(() => {
                let response = $.ajax({
                    url: $siteSettings.billingGroupRestangularized().all("PaymentModule").one("AccessToken").getRestangularUrl(),
                    async: false,
                    cache: false,
                }).responseText;
                return JSON.parse(response);
            })
            .OnInitialized(onInitCallback);

        if (optionsCopy.paymentAmount > 0) {
            pmForm.IncludePayment({ paymentAmount: optionsCopy.paymentAmount });
        }

        if (optionsCopy.originatingUserInfo && optionsCopy.originatingUserInfo.originatingUserId && optionsCopy.originatingUserInfo.originatingUserTypeId) {
            pmForm = pmForm.WithOriginatingInformation(optionsCopy.originatingUserInfo.originatingUserId, optionsCopy.originatingUserInfo.originatingUserTypeId);
        }

        if (optionsCopy.tokenizedAmount > 0) {
            pmForm = pmForm.IncludeToken({ paymentAmount: optionsCopy.tokenizedAmount });
        }

        return pmForm;
    }
}

namespace zotec.patientExperience.Services {
        export interface IPaymentModuleFormService {
            configureForm(pmForm: payments.IPaymentModuleForm,
                options: PaymentModuleFormOptions,
                onInitCallback?: IFormCallback)
        }
    }
namespace zotec.patientExperience {
    export class PaymentModuleFormOptions {
        chargeType: string;
        appName: string;
        entityId: string = "";
        locationId: string = "";
        sessionData: string = null;
        charges: patientExperience.payments.IChargePaymentInfo[];
        paymentAmount: number = 0;
        originatingUserInfo: any;
        onSuccessCallback: IFormSuccessCallback;
        webhookUrl: string;
        tokenizedAmount: number = 0;
    }
}

interface IFormCallback {
    (): void
}

interface IFormSuccessCallback {
    (msg: zotec.patientExperience.payments.WebHookResponse): void
}
